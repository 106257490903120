import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import Layout from "../layouts/Layout"
import Seo from "../components/Seo"
import Header from "../components/Header"
import Nav from "../components/Header/Nav"
import Summary from "../components/Header/Summary"
import Cards from "../components/Rows/Cards"
import Rows from "../components/Rows"
import { getCurrSlugSection } from "../util/helpers"
import { useThemeUI } from "theme-ui"

const PageIndex = ({ data, location }) => {
  const context = useThemeUI()
  const { setColorMode } = context
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    if (
      data &&
      data.allContentfulIndexPage &&
      data.allContentfulIndexPage.edges[0]
    ) {
      const page = data.allContentfulIndexPage.edges[0].node
      const pageTheme = page.pageTheme
        ? page.pageTheme.toLowerCase()
        : "default"
      setColorMode(pageTheme)
    }
  }, [setColorMode, data])

  const {
    title,
    sections,
    slug,
    summary,
    seo,
    rows,
    headerType,
    headerVideo,
    headerMedia,
    headerOverlay,
    headerOverlayOpacity,
    eNewsHeading,
    eNewsText,
    eNewsMailchimp,
  } = data.allContentfulIndexPage.edges[0].node

  let currSlug, currSection
  if (sections) {
    const currSlugSection = getCurrSlugSection(location, sections)
    currSlug = currSlugSection.currSlug
    currSection = currSlugSection.currSection
  }

  useEffect(() => {
    if (sections && currSlug) {
      setCurrentIndex(sections.indexOf(currSection))
    }
  }, [currSlug])

  if (
    !data &&
    !data.allContentfulIndexPage &&
    !data.allContentfulIndexPage.edges[0]
  ) {
    return <p>Page data not found :(</p>
  }

  return (
    <Layout eNewsSettings={{ eNewsHeading, eNewsText, eNewsMailchimp }}>
      <Seo
        title={seo && seo.title ? seo.title : title}
        description={seo && seo.description ? seo.description : null}
        image={seo && seo.image && seo.image.fluid ? seo.image.fluid.src : null}
      />
      <Header
        format={headerType}
        title={title}
        media={headerMedia}
        headerVideo={headerVideo}
        isOverlay={headerOverlay}
        overlayOpacity={headerOverlayOpacity}
      />
      {sections && (
        <Nav
          sections={sections}
          parentSlug={slug}
          currSlug={currSlug}
          setCurrentIndex={setCurrentIndex}
          currentIndex={currentIndex}
        />
      )}
      <Summary data={summary} />
      {sections ? (
        sections.map(({ cards }, i) => (
          <Cards isCurrent={currentIndex === i} content={cards} />
        ))
      ) : (
        <p style={{ textAlign: "center", padding: "100px 20px" }}>
          Index Page has no Sections...
        </p>
      )}
      <Rows data={rows} />
    </Layout>
  )
}

export default PageIndex

export const query = graphql`
  query($parentSlug: String!) {
    allContentfulIndexPage(filter: { slug: { eq: $parentSlug } }) {
      edges {
        node {
          title
          slug
          pageTheme
          summary {
            childMarkdownRemark {
              html
            }
          }
          headerMedia {
            title
            file {
              url
              contentType
            }
          }
          headerType
          headerOverlay
          headerOverlayOpacity
          headerVideo {
            assetId
            playbackId
          }
          eNewsHeading
          eNewsText
          eNewsMailchimp
          sections {
            title
            slug
            cards {
              title
              description {
                childMarkdownRemark {
                  html
                }
              }
              thumbnail {
                title
                fluid {
                  ...GatsbyContentfulFluid
                }
                description
              }
              link {
                slug
              }
            }
          }

          rows {
            __typename
            ... on ContentfulRowShapes {
              shapes {
                ... on ContentfulShapeLink {
                  heading
                  description
                  theme
                  type
                  linkText
                  linkUrl
                }
              }
            }
          }
          seo {
            title
            description
            image {
              fluid(maxWidth: 1000) {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
    }
  }
`
